/* Section */
.section {
  clear: both;
  margin: 0px;
  padding: 0px;
}
/* Column */
.col {
  display: block;
  float:left;
  margin: 1% 0 1% 1.6%;
}
.col:first-child { margin-left: 0 !important; }

/* Row */
.row:before,
.row:after { content:""; display:table; }
.row:after { clear:both;}
.row { zoom:1; /* For IE 6/7 */ }

/* Grid */
.grid_1_of_2{ width: 49.2% }
.grid_2_of_2{ width: 100% } 

/* Grid */
.grid_1_of_3{ width: 31.7% }
.grid_2_of_3{ width: 63.4% } 
.grid_3_of_3{ width: 100% } 

/* Full Width below 768 pixels */
@media only screen and (max-width: 768px) {
  .col {  margin: 1% 0 1% 0%; }
}

.text-center {
  text-align: center;
}

.right {
  float: right;
}

// Containers

.standard-container {
  padding-top: 80px; // same as the svg height
  padding-bottom: 80px; // same as the svg height
  @media only screen and (max-width: 768px) {
    padding-top: 40px; // same as the svg height
    padding-bottom: 40px; // same as the svg height
  }
}



.home-container {
  position: relative;
  .home-img {
    width: 60%;
  }
  .home-small {
    position: absolute;
    top: 10%;
    right: 25%;
    z-index: 2;
    @media only screen and (max-width: 768px) {
      top: 0%;
      right: 25%;
    }
    .home-drop-left {
      position: absolute;
      top: 40%;
      left: -45%;
      width: 40%;
      @media only screen and (max-width: 768px) {
        top: 35%;
        left: -40%;
        width: 30%;
      }
    }
    .home-drop-right {
      position: absolute;
      bottom: 25%;
      right: -55%;
      width: 50%;
      @media only screen and (max-width: 768px) {
        bottom: 30%;
        right: -50%;
        width: 40%;
      }
    }
  }
  .home-medium {
    position: absolute;
    top: 40%;
    right: 10%;
    z-index: 1;
    @media only screen and (max-width: 768px) {
      top: 35%;
      right: 5%;
    }
  }
}

.top-container {
  padding-bottom: 80px; // same as the svg height
  .top-img {
    max-height: 300px;
    margin: 20px;
  }
  @media only screen and (max-width: 768px) {
    padding-bottom: 40px; // same as the svg height
  }
}

.test-home {
  background-image: radial-gradient( circle closest-side, rgba($light-brown, $opacity-60), rgba($dark-brown, $opacity-80));
  position: relative;
}

// Container Colours

// LIGHT BLUE TOP
.light-blue-container-top {
  background-color: rgba($light-blue, $opacity-80);
  color: $text-color-light;
  position: relative;
}

.light-blue-container-top::after {
  content: "";
  font-size: 0;
  position: absolute;
  top: 100%;
  left: 0;
  height: 70px; 
  width: 100%;
  transform: rotate(180deg);
  background: url("./images/container-top.svg") no-repeat bottom center;
  background-size: 100%;
  @media only screen and (max-width: 768px) {
    height: 25px;
  }
}

// LIGHT BLUE
.light-blue-container {
  background-color: rgba($light-blue, $opacity-80);
  color: $text-color-light;
  position: relative;
}

.light-blue-container::before {
  content: "";
  position: absolute;
  font-size: 0;
  top: -70px;
  left: 0;
  height: 70px; 
  width: 100%;
  background: url("./images/container-top.svg") no-repeat bottom center;
  background-size: 100%;
  @media only screen and (max-width: 768px) {
    top: -25px;
    height: 25px;
  }
}

.light-blue-container::after {
  content: "";
  font-size: 0;
  position: absolute;
  top: 100%;
  left: 0;
  height: 70px; 
  width: 100%;
  transform: rotate(180deg);
  background: url("./images/container-top.svg") no-repeat bottom center;
  background-size: 100%;
  @media only screen and (max-width: 768px) {
    height: 25px;
  }
}

//  LIGHT BROWN
.light-brown-container {
  background-image: radial-gradient( circle closest-side, rgba($light-brown, $opacity-60), rgba($dark-brown, $opacity-80));
  position: relative;
}

.light-brown-container::before {
  content: "";
  position: absolute;
  font-size: 0;
  top: -70px;
  left: 0;
  height: 70px; 
  width: 100%;
  transform: rotate(180deg);
  background: url("./images/container-bottom-brown.svg") no-repeat top center;
  background-size: 100%;
  @media only screen and (max-width: 768px) {
    top: -25px;
    height: 25px; 
  }
}

.light-brown-container::after {
  content: "";
  position: absolute;
  font-size: 0;
  top: 100%;
  left: 0;
  height: 70px; 
  width: 100%;
  background: url("./images/container-bottom-brown.svg") no-repeat top center;
  background-size: 100%;
  @media only screen and (max-width: 768px) {
    height: 25px;
    margin-bottom: -1px;
  }
}

// Nutrition Blocks
.nutri-h2 {
  font-family: $KinderBoy-font-family;
  font-size: 6rem;
  margin-top: 20px;
  margin-left: 20px;
  @media only screen and (max-width: 768px) {
    font-size: 3rem;
  }
}

.nutrition-header {
  font-family: $KinderBoy-font-family;
  position: relative;
  .nutri-bar {
    width: 65%;
  }
  .nutri-img {
    position: relative;
    z-index: 2;
  }
}

.nutri-container {
  padding-bottom: 40px;
  @media only screen and (max-width: 768px) {
    padding-bottom: 20px;
  }
}

.nutrition-header-red::before {
  content: "";
  position: absolute;
  font-size: 0;
  top: 50%;
  left: 0;
  height: 340px; 
  width: 100%;
  z-index: -1;
  background: url("./images/swish-red.svg") no-repeat bottom center;
  background-size: 100%;
  @media only screen and (max-width: 768px) {
    height: 110px;
    margin-bottom: -1px;
  }
}

.nutrition-header-brown::before {
  content: "";
  position: absolute;
  font-size: 0;
  top: 50%;
  left: 0;
  height: 340px; 
  width: 100%;
  z-index: -1;
  background: url("./images/swish-brown.svg") no-repeat bottom center;
  background-size: 100%;
  @media only screen and (max-width: 768px) {
    height: 110px;
    margin-bottom: -1px;
  }
}

.nutrition-header-orange::before {
  content: "";
  position: absolute;
  font-size: 0;
  top: 50%;
  left: 0;
  height: 340px; 
  width: 100%;
  z-index: -1;
  background: url("./images/swish-orange.svg") no-repeat bottom center;
  background-size: 100%;
  @media only screen and (max-width: 768px) {
    height: 110px;
    margin-bottom: -1px;
  }
}

.nutrition {
  font-family: $KinderBoy-font-family;
  .nutri-title {
    font-size: 1.5rem;
    width: 100%;
  }
  .nutri-table {
    display: flex;
    padding-left: 40px;
    padding-right: 40px;
    p {
      font-size: 1.5rem;
      margin-bottom: 0;
    }
    .nutri-col {
      flex: 0 0 30.333333%;
      margin-left: 1.5%;
      margin-right: 1.5%;
    }
    @media only screen and (max-width: 768px) {
      padding-left: 0px;
      padding-right: 0px;
      p {
        font-size: 1rem;
    }
    }
  }
  .nutri-info {
    text-align: center;
  }
  .nutri-al {
    text-align: center;
  }
}

.nutrition.nutrition-red {
  color: $dark-red;
  .nutri-info {
    background-color: $light-red;
    opacity: $opacity-60;
    color: #FFFFFF;
  }
  .nutri-al {
    background-color: $dark-red;
    color: #FFFFFF;
  }
}

.nutrition.nutrition-brown {
  color: $dark-brown;
  .nutri-info {
    background-color: $brown;
    opacity: $opacity-60;
    color: #FFFFFF;
  }
  .nutri-al {
    background-color: $dark-brown;
    color: #FFFFFF;
  }
}

.nutrition.nutrition-orange {
  color: $dark-orange;
  .nutri-info {
    background-color: $light-orange;
    opacity: $opacity-60;
    color: #FFFFFF;
  }
  .nutri-al {
    background-color: $dark-orange;
    color: #FFFFFF;
  }
}

.whats-in-footer {
  font-family: $KinderBoy-font-family;
  text-align: center;
  width: 100%;
  font-size: 2rem;
  margin-bottom: 0;
  @media only screen and (max-width: 768px) {
    font-size: 1rem;
  }
}

.nutri-right {
  position: absolute;
  bottom: 10%;
  right: 5%;
}

// Ideas Section

.ideas-container {
  padding-top: 20px; // same as the svg height
  padding-bottom: 20px; // same as the svg height
  @media only screen and (max-width: 768px) {
    padding-top: 10px; // same as the svg height
    padding-bottom: 10px; // same as the svg height
  } 
}

.ideas {
  position: relative;
  .top-right {
    position: absolute;
    top: -2%;
    right: 5%;
  }
  .top-left {
    position: absolute;
    top: -2%;
    left: 5%;
  }
}

.ideas-orange::after {
  content: "";
  position: absolute;
  font-size: 0;
  top: 87%;
  left: 0;
  height: 210px; 
  width: 100%;
  z-index: -1;
  background: url("./images/curve-right-orange.svg") no-repeat bottom center;
  background-size: 100%;
  @media only screen and (max-width: 768px) {
    height: 80px;
    margin-bottom: -1px;
  }
}

.ideas-brown::after {
  content: "";
  position: absolute;
  font-size: 0;
  top: 87%;
  left: 0;
  height: 230px; 
  width: 100%;
  z-index: -1;
  background: url("./images/curve-left-brown.svg") no-repeat bottom center;
  background-size: 100%;
  @media only screen and (max-width: 768px) {
    height: 90px;
    margin-bottom: -1px;
  }
}

.ideas-light-blue::after {
  content: "";
  position: absolute;
  font-size: 0;
  top: 87%;
  left: 0;
  height: 210px; 
  width: 100%;
  z-index: -1;
  background: url("./images/curve-right-light-blue.svg") no-repeat bottom center;
  background-size: 100%;
  @media only screen and (max-width: 768px) {
    height: 80px;
    margin-bottom: -1px;
  }
}

.ideas-dark-blue::after {
  content: "";
  position: absolute;
  font-size: 0;
  top: 87%;
  left: 0;
  height: 230px; 
  width: 100%;
  z-index: -1;
  background: url("./images/curve-left-dark-blue.svg") no-repeat bottom center;
  background-size: 100%;
  @media only screen and (max-width: 768px) {
    height: 90px;
    margin-bottom: -1px;
  }
}

.ideas-last {
  padding-top: 20px; // same as the svg height
  padding-bottom: 40px; // same as the svg height
  @media only screen and (max-width: 768px) {
    padding-top: 10px; // same as the svg height
    padding-bottom: 20px; // same as the svg height
  } 
}

// Page details

.get-in-touch-img {
  max-height: 18rem;
  margin-left: 15px;
  margin-right: 15px;
  @media only screen and (max-width: 768px) {
    max-height: 10rem;
  }
}

.get-in-touch-text-top { 
  font-family: $KinderBoy-font-family;
  text-align: center;
  padding-left: 15px;
  padding-right: 15px;
  p {
    font-size: 2rem;
    margin-bottom: 0;
    @media only screen and (max-width: 768px) {
      font-size: 1rem;
    }
  }
}

.get-in-touch-text { 
  font-family: $KinderBoy-font-family;
  text-align: center;
  padding-left: 15px;
  padding-right: 15px;
  p {
    font-size: 2rem;
    margin-bottom: 0;
    @media only screen and (max-width: 768px) {
      font-size: 1rem;
    }
  }
}

.text-angle-left {
  transform: rotate(-5deg);
}

.text-angle-right {
  transform: rotate(5deg);
}

.circle-small {
  font-family: $KinderBoy-font-family;
  width: 12rem;
  height: 12rem;
  border-radius: 50%;
  font-size: 2rem;
  line-height: 2rem;
  color: $text-color-light;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  background: #000;
  p {
    margin-bottom: 0;
  }
  @media only screen and (max-width: 768px) {
    font-size: 1rem;
    line-height: 1rem;
    width: 6rem;
    height: 6rem;
  }
}

.circle-medium {
  font-family: $KinderBoy-font-family;
  width: 16rem;
  height: 16rem;
  border-radius: 50%;
  font-size: 2rem;
  line-height: 2rem;
  color: $text-color-light;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  background: #000;
  p {
    margin-bottom: 0;
  }
  @media only screen and (max-width: 768px) {
    font-size: 1rem;
    line-height: 1rem;
    width: 8rem;
    height: 8rem;
  }
}

.contact-btn {
  box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.4);
  margin: 20px auto;
  font-size: 48px;
  line-height: 3rem;
  width: 14rem;
  height: 14rem;
  transition: all 0.3s ease 0s;
  cursor: pointer;
  @media only screen and (max-width: 768px) {
    font-size: 1rem;
    line-height: 1rem;
    width: 8rem;
    height: 8rem;
  }
}

.contact-btn:hover {
  background-color: #2EE59D;
  box-shadow: 0px 15px 20px rgba(0, 0, 0, 0.6);
  transform: translateY(-7px);
}

.contact-link:hover {
  text-decoration: none;
}

.never-toast-img {
  margin: 0px 80px 0px 60px;
}

.never-toast-text {
  font-family: $KinderBoy-font-family;
  font-size: 1.2rem;
  color: #FFFFFF;
}

.background-brown {
  background: linear-gradient(rgba($brown, 0.8), rgba($brown, 0.8)), url("./images/background.jpg");
}

.background-dark-brown {
  background: linear-gradient(rgba($dark-brown, 1), rgba($dark-brown, 1)), url("./images/background.jpg");
}

.background-red {
  background: linear-gradient(rgba($light-red, 0.8), rgba($light-red, 01)), url("./images/background.jpg");
}

.background-dark-red {
  background: linear-gradient(rgba($dark-red, 1), rgba($dark-red, 1)), url("./images/background.jpg");
}

.background-orange {
  background: linear-gradient(rgba($light-orange, 0.8), rgba($light-orange, 0.8)), url("./images/background.jpg");
}

.background-dark-orange {
  background: linear-gradient(rgba($dark-orange, 1), rgba($dark-orange, 1)), url("./images/background.jpg");
}

.background-blue {
  background: linear-gradient(rgba($light-blue, 0.8), rgba($light-blue, 0.8)), url("./images/background.jpg");
}

.background-dark-blue {
  background: linear-gradient(rgba($dark-blue, 0.8), rgba($dark-blue, 0.8)), url("./images/background.jpg");
}

.background-white {
  background: linear-gradient(rgba(255, 255, 255, 0.8), rgba(255, 255, 255, 0.8)), url("./images/background.jpg");
  color: $light-brown;
}
