/**
 * Site header
 */
.site-header {
  min-height: $spacing-unit * 1.865;
  background-color: rgba($dark-blue, $opacity-90);
  position: relative;
  -webkit-box-shadow: 0px 0px 10px 5px rgba(0,0,0,0.5);
  -moz-box-shadow: 0px 0px 10px 5px rgba(0,0,0,0.5);
  box-shadow: 0px 0px 10px 5px rgba(0,0,0,0.5);
}

.site-logo {
  max-height: 6rem;
  @media screen and (max-width: 767px) {
    max-width: 75%;
  }
}

header {
  overflow: visible;
  z-index: 100;
  background-color: transparent;
  // height: 5rem;
  padding: 1.5rem;
  
  span.menu-control-open,
  span.menu-control-close,
  nav,
  a {
    font-family: $KgLifeIsMessy-font-family;
    color: #F0F0F0;
    font-size: 1.5rem;
  }
  
  span.menu-control-open,
  span.menu-control-close,
  nav a {
    font-size: 1.5rem;
    margin-bottom: 1.5rem; 
  }
  
  span.menu-control-open,
  span.menu-control-close {
    display: block;
    cursor: pointer; 
  }
  
  span.menu-control-open {
    position: relative;
    font-size: 1.2rem;
    line-height: 150%;
    float: right;
    margin-top: 5px;
    svg {
        position: relative;
        top: 12px;
        left: 12px;
        fill: $text-color-light;
        width: 40px;
        height: 40px;
    }
    &:before {
        padding-top: 1px;
    }
  }
  
  span.menu-control-close {
    font-size: 1.2rem;
    text-align: right; 
  }
  
  nav {
    display: flex;
    flex-direction: column; 
  }
}

.menu {
  position: absolute;
  right: 0;
  top: 0;
  background-color: #373331;
  color: #F0F0F0;
  height: 100vh;
  width: 0;
  overflow: hidden;
  transition: all .3s ease-in-out;
  a {
    transform: translate3d(-150px, 0, 0);
    transition: transform 200ms ease-in; 
  }
  
  .menu-inner {
    position: relative;
    height: 100%;
    padding: 1.5rem; 
  }
}

.menu--active {
  height: 100vh;
  overflow: hidden; 
  
  nav a {
    transform: translate3d(0, 0, 0);
    transition: transform 600ms ease-out; 
  
    /* Handles items within the slide-out to give them the delayed slide-in */
    &:first-child {
      transition-delay: 200ms; 
    }
    &:nth-child(2) {
      transition-delay: 300ms; 
    }
    &:nth-child(3) {
      transition-delay: 350ms; 
    }
    &:nth-child(4) {
      transition-delay: 400ms; 
    }
    &:last-child {
      transition-delay: 450ms; 
    }
  }
}

// This media query sets the width of the menu to be larger on a smaller screen
 @media screen and (max-width: 767px) {
  .menu--active .menu {
    width: 100%; }
}

// This media query sets the menu width to be slightly smaller on a larger screen (think an iPad)
@media screen and (min-width: 768px) and (max-width: 1024px) {
  .menu--active .menu {
    width: 40%;
    .menu-inner {
      width: 40vw;
    }
  }
}
  
// This media query styles the menu for a large environment (think a desktop)
@media screen and (min-width: 1024px) {
  header {
    padding: 2.5rem;
    
    span.menu-control-open,
    span.menu-control-close {
      display: none; 
    }
    
    .menu {
      position: relative;
      background-color: transparent;
      width: auto;
      height: auto; 
      
      .menu-inner {
        padding: 0;
        width: 100%; 
      }
      
      a {
        transform: none;
        transition: none; 
      }
    }
  
    nav {
      flex-direction: row;
      justify-content: flex-end;
      text-align: center;
      align-items: center;
      justify-content: center;
      padding-top: 1.2rem;
      a {
        font-size: 1.2rem;
        margin-left: 2rem;
        margin-bottom: 0px; 
      }
    }
  }
}