@charset "utf-8";

// Define defaults for each variable.

$base-font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol" !default;
$base-font-size:   16px !default;
$base-font-weight: 400 !default;
$small-font-size:  $base-font-size * 0.875 !default;
$base-line-height: 1.5 !default;

$spacing-unit:     30px !default;

$text-color:       #1D1D1B !default;
$text-color-light: #FFFFFF !default;
$background-color: #fdfdfd !default;
$brand-color:      #2a7ae2 !default;

$dark-blue: 		#1D70B7 !default;
$light-blue: 		#6FB2DC !default;

$dark-brown: 		#822b00	!default;
$brown:				#B25139 !default;
$light-brown: 		#d47939	!default;

$dark-orange: 		#df6e2e	!default;
$light-orange: 		#ed7036	!default;

$dark-red: 			#d22e14 !default;
$light-red: 		#e30613 !default;

$shadow-blue: 		#154976 !default;

$opacity-90: 		0.9 !default;
$opacity-80: 		0.8 !default;
$opacity-60: 		0.6 !default;
$opacity-40: 		0.4 !default;

$grey-color:       #828282 !default;
$grey-color-light: lighten($grey-color, 40%) !default;
$grey-color-dark:  darken($grey-color, 25%) !default;

$table-text-align: left !default;

// Width of the content area
$content-width:    1000px !default;

$on-palm:          600px !default;
$on-laptop:        800px !default;

// Custom fonts
$blackjack-font-family: "blackjack", Arial;
$VeneerTwo-font-family: "VeneerTwo", Arial;
$IntroScript-font-family: "IntroScript", Arial;
$KgLifeIsMessy-font-family: "KgLifeIsMessy", Arial;
$KinderBoy-font-family: "KinderBoyRegular", Arial;

// Use media queries like this:
// @include media-query($on-palm) {
//   .wrapper {
//     padding-right: $spacing-unit / 2;
//     padding-left: $spacing-unit / 2;
//   }
// }
@mixin media-query($device) {
  @media screen and (max-width: $device) {
    @content;
  }
}

@mixin relative-font-size($ratio) {
  font-size: $base-font-size * $ratio;
}

@font-face {
	font-family: "blackjack";
	src: url("../assets/fonts/blackjack.otf");
}

@font-face {
	font-family: "VeneerTwo";
	src: url("../assets/fonts/VeneerTwo.ttf");
}

@font-face {
	font-family: "IntroScript";
	src: url("../assets/fonts/IntroScriptDemo-Medium.ttf");
}

@font-face {
	font-family: "KgLifeIsMessy";
	src: url("../assets/fonts/KgLifeIsMessy-Z518.ttf");
}

@font-face {
	font-family: "KinderBoyRegular";
	src: url("../assets/fonts/KinderBoyRegular-EMZW.ttf");
}

// Import partials.
@import
  "minima/base",
  "minima/layout",
  "minima/header",
  "minima/content",
  "minima/syntax-highlighting"
;
