/**
 * Site footer
 */
.site-footer {
  border-top: 1px solid $grey-color-light;
  padding: $spacing-unit 0;
  color: #FFFFFF;
  background-color: #1D71B8;
  font-family: $KgLifeIsMessy-font-family
}

.footer-heading {
  @include relative-font-size(1.125);
  margin-bottom: $spacing-unit / 2;
  color: #FFFFFF;
}

.contact-list,
.social-media-list {
  list-style: none;
  margin-left: 0;
}

.contact-list li {
  margin-bottom: 15px;
}

.sunshine-img {
  max-width: 200px;
  display: block;
  margin: auto;
}

.sunshine-link {
  font-family: Helvetica, Sans-serif;
  text-align: center;
  color: #FFFFFF;
  padding-top: 15px;
}

.footer-col-wrapper {
  @include relative-font-size(0.9375);
  color: $grey-color;
  margin-left: -$spacing-unit / 2;
  @extend %clearfix;
}

.footer-col {
  float: left;
  margin-top: $spacing-unit / 2;
  margin-bottom: $spacing-unit / 2;
  padding-left: $spacing-unit / 2;
  a {
    color: #FFFFFF;
  }
}

.footer-col-1 {
  width: -webkit-calc(33% - (#{$spacing-unit} / 2));
  width:         calc(33% - (#{$spacing-unit} / 2));
}

.footer-col-2 {
  width: -webkit-calc(33% - (#{$spacing-unit} / 2));
  width:         calc(33% - (#{$spacing-unit} / 2));
}

.footer-col-3 {
  width: -webkit-calc(33% - (#{$spacing-unit} / 2));
  width:         calc(33% - (#{$spacing-unit} / 2));
}

@include media-query($on-laptop) {
  .footer-col-1,
  .footer-col-2 {
    width: -webkit-calc(50% - (#{$spacing-unit} / 2));
    width:         calc(50% - (#{$spacing-unit} / 2));
  }

  .footer-col-3 {
    width: -webkit-calc(100% - (#{$spacing-unit} / 2));
    width:         calc(100% - (#{$spacing-unit} / 2));
  }
}

@include media-query($on-palm) {
  .footer-col {
    float: none;
    width: -webkit-calc(100% - (#{$spacing-unit} / 2));
    width:         calc(100% - (#{$spacing-unit} / 2));
  }
}



/**
 * Page content
 */
.page-content {
  padding-top: $spacing-unit;
  padding-bottom: 100px;
  flex: 1;
}

.page-heading {
  @include relative-font-size(2);
}

.post-list-heading {
  @include relative-font-size(1.75);
}

.post-list {
  margin-left: 0;
  list-style: none;

  > li {
    margin-bottom: $spacing-unit;
  }
}

.post-meta {
  font-size: $small-font-size;
  color: $grey-color;
}

.post-link {
  display: block;
  @include relative-font-size(1.5);
}



/**
 * Posts
 */
.post-header {
  margin-bottom: $spacing-unit;
}

.post-title {
  @include relative-font-size(2.625);
  letter-spacing: -1px;
  line-height: 1;

  @include media-query($on-laptop) {
    @include relative-font-size(2.25);
  }
}

.post-content {
  margin-bottom: $spacing-unit;

  h2 {
    @include relative-font-size(2);

    @include media-query($on-laptop) {
      @include relative-font-size(1.75);
    }
  }

  h3 {
    @include relative-font-size(1.625);

    @include media-query($on-laptop) {
      @include relative-font-size(1.375);
    }
  }

  h4 {
    @include relative-font-size(1.25);

    @include media-query($on-laptop) {
      @include relative-font-size(1.125);
    }
  }
}

.pt-2 {
  padding-top: 2rem;
}

.pt-3 {
  padding-top: 3rem;
}

.pt-4 {
  padding-top: 4rem;
}

.pt-5 {
  padding-top: 5rem;
}

.p-1 {
  padding: 1rem;
}

.py-1 {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.py-2 {
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.py-3 {
  padding-top: 3rem;
  padding-bottom: 3rem;
}

.py-4 {
  padding-top: 4rem;
  padding-bottom: 4rem;
}

.py-5 {
  padding-top: 5rem;
  padding-bottom: 5rem;
}

.pl-5 {
  padding-left: 5rem;
  @media only screen and (max-width: 768px) {
    padding-left: 2.5rem;
  }
}

.pr-5 {
  padding-right: 5rem;
}

.mb-5 {
  margin-bottom: 5rem;
}
